/*
? First, let's import the essentials - React and Styled
*/
import { styled } from "@mui/system";
import React from "react";
/*
? Next, let's import all the UI components we intend to use on this page from Mui.
*/

import MapBoxHeatMap from "../Heatmap/MapboxHeat";

const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "1fr",
  gap: "1rem",
  width: "100%",
  height: "100%",
}));

const HeatMapForDeveloperAndProfessional = ({
	developresAreaOfOperations,
	professionalAreaOfOperations,
}: {
	developresAreaOfOperations: any;
	professionalAreaOfOperations: any;
}) => {
	return (
		<React.Fragment>
			<MainContainer>
				<MapBoxHeatMap
					GeoJSON={developresAreaOfOperations}
					title="developers"
				/>
				<MapBoxHeatMap
					GeoJSON={professionalAreaOfOperations}
					title="professionals"
				/>
			</MainContainer>
		</React.Fragment>
	);
};
/*
& FInally, let's export the component
*/
export default HeatMapForDeveloperAndProfessional;
