/*

* This library function accepts a number representing the number of seconds elapsed and returns a string representing the time elapsed in the format of Years, Days, Hours, Minutes, Seconds.
^@params {number} seconds
^@returns {string} timeString

*/

/*

& Let's being by describing the function.

*/

const generateTimeString : (seconds: number) => string = (seconds: number) => {
    const yearsElapsed : number = Math.floor(seconds / 31536000);
    const daysElapsed : number = Math.floor((seconds % 31536000) / 86400);
    const hoursElapsed : number = Math.floor(((seconds % 31536000) % 86400) / 3600);
    const minutesElapsed : number = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    const secondsElapsed : number = Math.floor((((seconds % 31536000) % 86400) % 3600) % 60);
    const timeString : string = (yearsElapsed > 0 ? (yearsElapsed + "y ") : " ") + (daysElapsed > 0 ? (daysElapsed + "d ") : " ") + (hoursElapsed > 0 ? (hoursElapsed + "h ") : " ") + (minutesElapsed > 0 ? (minutesElapsed + "m ") : " ") + (secondsElapsed > 0 ? (secondsElapsed + "s ") : " ");
    return timeString;
};

/*

& Finally, let's export the function.

*/

export default generateTimeString;