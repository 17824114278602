/* Common Imports */

import { styled } from "@mui/system";
import { merge } from "lodash";
import React from "react";

/* Component Imports */

import { Typography } from "@mui/material";
import ReactApexChart from ".";
import BaseOptionChart from "./plotOptions";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  // margin: "0rem 0rem 1rem 0rem",
  ".apexcharts-tooltip": {
    color: "#000000",
  },
  ".apexcharts-text ": {
    fill: theme.palette.mode == "dark" ? "#FFFFFF" : "#000000",
  },
  // padding: "1rem 0rem 1rem 0rem",
  border: "1px",
  boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
}));

const Title = styled(Typography)(({ theme }) => ({
  padding: "1rem",
  fontSize: "1.5rem",
  fontWeight: "400",
  lineHeight: "123.5%",
}));

const AnalyticsPieChart = (props: any) => {
  /* x-axis data */

  const chartOptions = merge(BaseOptionChart(), {
    labels: props.graphData?.x,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: 0,
    },
  });

  /* series data */

  //   const seriesData = props.graphData.y.map((item: number, index: number) => ({
  //     x: props.graphData.x
  //     y: item,
  //   }));

  const series = props.graphData?.y;

  return (
    <React.Fragment>
      <MainContainer>
        <Title variant="h6">{props.title}</Title>
        <ReactApexChart
          type="pie"
          series={series}
          options={chartOptions}
          height={450}
          width="100%"
        />
      </MainContainer>
    </React.Fragment>
  );
};

export default AnalyticsPieChart;
