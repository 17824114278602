/* Common Imports */

import { styled } from "@mui/system";
import { merge } from "lodash";
import React from "react";

/* Component Imports */

import { Typography } from "@mui/material";
import ReactApexChart from ".";
import BaseOptionChart from "./BaseOptionChart";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	width: "100%",
	margin: "0rem 0rem 1rem 0rem",
	".apexcharts-tooltip": {
		color: "#000000",
	},
	".apexcharts-text ": {
		fill: theme.palette.mode == "dark" ? "#FFFFFF" : "#000000",
	},
	// padding: "1rem 0rem 1rem 0rem",
	border: "1px",
	boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
	backgroundColor: "#ffffff",
}));

const Title = styled(Typography)(({ theme }) => ({
	padding: "1rem",
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	// border : "1px solid #F76C35",
	// borderRadius : "5px",
	// color : "#F76C35",
	// boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
}));

const AnalyticsBarGraph = (props: any) => {
	/* x-axis data */
	const chartOptions = merge(BaseOptionChart(), {
		xaxis: {
			type: props.graphData?.x,
		},
	});

	/* series data */

	const seriesData = props.graphData?.y.map((item: number, index: number) => ({
		x: props.title.includes("Budget")
			? props.graphData.x[index]
			: props.title.includes("PDF")
				? props.graphData.x[index]
				: props.title.includes("Top User")
					? (props.graphData.x[index].name ? props.graphData.x[index].name:props.graphData.x[index]._id)
					: props.graphData.x[index].title || props.graphData.x[index].name,
		y: item,
	}));

	const series = [
		{
			name: props.title,
			data: seriesData,
		},
  ];
  console.log("Series Data:", seriesData); // Log series data for debugging
	console.log("Series:", series); 

	return (
		<React.Fragment>
			<MainContainer>
				<Title variant="h6">{props.title}</Title>
				<ReactApexChart
					type="bar"
					series={series}
					options={chartOptions}
					height={450}
					width="100%"
				/>
			</MainContainer>
		</React.Fragment>
	);
};

export default AnalyticsBarGraph;
