/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/

import Container from "./Container";
import Header from "./Header";
import LayoutFooter from "./LayoutFooter";
import LayoutHeader from "./LayoutHeader";

/*

? Next, let's import all the utility components we need on this page

*/

import { Helmet } from "react-helmet";

/*

& Next, let's describe all the styled components we need

*/

type MarginalizeProp = {
	marginalize?: string;
};

const TopMarginGenerator = styled("div")(({ theme }) => ({
	margin: "6rem 0rem 0rem 0rem",
}));

const TopMarginGeneratorBasic = styled("div")(({ theme }) => ({
	margin: "4rem 0rem 0rem 0rem",
}));

const TopMarginGeneratorMarginalize = styled("div", {
	shouldForwardProp: (prop) => prop !== "marginalize",
})<MarginalizeProp>(({ theme, marginalize }) => ({
	margin: (marginalize || "1rem") + " 0rem 0rem 0rem",
}));

const BottomMarginGenerator = styled("div")(({ theme }) => ({
	margin: "2rem 0rem 0rem 0rem",
}));

/*

& Next, let's describe the component

*/

const Layout = ({
	children,
	hideStartMargin,
	hideEndMargin,
	marginalize,
	session,
	new_notification,
	showNotification,
	setShowNotification,
}: {
	children?: React.ReactNode;
	hideStartMargin?: boolean;
	hideEndMargin?: boolean;
	marginalize?: string;
	session?: any;
	new_notification?: any;
	showNotification?: boolean;
	setShowNotification?: any;
}) => {
	return (
		<React.Fragment>
			<Header />
			<Helmet>
				<html />
			</Helmet>
			{!hideStartMargin ? <TopMarginGenerator /> : <TopMarginGeneratorBasic />}
			<LayoutHeader
				session={session}
				new_notification={new_notification ? new_notification : null}
				showNotification={showNotification}
				setShowNotification={setShowNotification}
			/>
			{React.Children.map(children, (child: any, key: number) => (
				<React.Fragment>
					<TopMarginGeneratorMarginalize marginalize={marginalize} />
					<Container
						key={key}
						unboundBackground={child.props.unboundBackground}
						containerize={child.props.containerize}
						gap={child.props.gap}
					>
						{child}
					</Container>
				</React.Fragment>
			))}
			{!hideEndMargin ? <BottomMarginGenerator /> : undefined}
			<LayoutFooter />
		</React.Fragment>
	);
};

/*

& Finally, let's export the component as default

*/

export default Layout;
