/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/
import { Typography } from "@mui/material";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

/* Mapbox API Key */

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmVlZ3J1cmVhbHR5IiwiYSI6ImNreGg3YzQyMTBhMWsyd3Via2UycjEwb2IifQ.tuAt_hnBl0fgWt_IKeuRbg";

/*

& Next, let's define the styled components we need on this page

*/

const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "0rem 0rem 1rem 0rem",
  padding: "1rem 0rem 1rem 0rem",
  border: "1px",
  boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
}));

const TitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  // padding: "1rem 1rem 1rem 1rem",
  // border: "1px",
  // boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
}));
const Title = styled(Typography)(({ theme }) => ({
  padding: "1rem",
  fontSize: "1.5rem",
  fontWeight: "400",
  lineHeight: "123.5%",
  textTransform: "capitalize",
}));

const Map = styled("div")(({ theme }) => ({
  height: "30rem",
  overflow: "hidden",
}));

const MapContainer = styled("div")(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  padding: "1rem 1rem 1rem 1rem",
}));

/*

& Next, let's describe the component

*/

const MapBoxHeatMap = (props: any) => {
  /*

    & Next, let's define a local state for the map. We will use the useRef hook to define the state .

  */

  const map = React.useRef<any>(null);

  /*

    & Next, let's define a local state for the map loaded. We will use the useState hook to define the state .

  */

  const [isMapboxThingyLoaded, setIsMapboxThingyLoaded] =
    React.useState<boolean>(false);

  /*

  & Next, let's define a useEffect hook to load the mapbox map.

  */

  React.useEffect(() => {
    if (isMapboxThingyLoaded) {
      return;
    }
    map.current = new mapboxgl.Map({
      container: map.current,
      style: "mapbox://styles/beegrurealty/ckxj2dccl09h714krfwuepaqt",
      center: [77.6324513, 13.0559193],
      zoom: 7,
    });
    map.current.on("load", () => {
      // Add a geojson point source.
      // Heatmap layers also work with a vector tile source.
      map.current.addSource("properties", {
        type: "geojson",
        data: props.GeoJSON,
      });

      map.current.addLayer(
        {
          id: "properties-heat",
          type: "heatmap",
          source: "properties",
          maxzoom: 9,
          paint: {
            // Increase the heatmap weight based on frequency and property magnitude
            "heatmap-weight": [
              "interpolate",
              ["linear"],
              ["get", `${props.price}`],
              0,
              0,
              6,
              1,
            ],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            "heatmap-intensity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              1,
              9,
              3,
            ],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(153,232,168,0)",
              0.2,
              "rgb(153,224,161)",
              0.4,
              "rgb(243,240,98)",
              0.6,
              "rgb(243,240,98)",
              0.8,
              "rgb(236,70,66)",
              1,
              "rgb(242,75,63)",
            ],
            // Adjust the heatmap radius by zoom level
            "heatmap-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              2,
              9,
              20,
            ],
            // Transition from heatmap to circle layer by zoom level
            "heatmap-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              7,
              1,
              9,
              0,
            ],
          },
        },
        "waterway-label"
      );

      map.current.addLayer(
        {
          id: "properties-point",
          type: "circle",
          source: "properties",
          minzoom: 7,
          paint: {
            // Size circle radius by earthquake magnitude and zoom level
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              7,
              [
                "interpolate",
                ["linear"],
                ["get", `${props.price}`],
                1,
                1,
                6,
                4,
              ],
              16,
              [
                "interpolate",
                ["linear"],
                ["get", `${props.price}`],
                1,
                5,
                6,
                50,
              ],
            ],
            // Color circle by earthquake magnitude
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", `${props.price}`],
              1,
              "rgba(201,203,244,0)",
              2,
              "rgb(103,169,207)",
              3,
              "rgb(209,229,240)",
              4,
              "rgb(253,219,199)",
              5,
              "rgb(239,138,98)",
              6,
              "rgb(178,24,43)",
            ],
            "circle-stroke-color": "white",
            "circle-stroke-width": 1,
            // Transition from heatmap to circle layer by zoom level
            "circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0, 8, 1],
          },
        },
        "waterway-label"
      );
    });
    setIsMapboxThingyLoaded(true);
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    map.current.on("click", "properties-point", (e: any) => {
      map.current.getCanvas().style.cursor = "pointer";
      const coordinates = e.features[0].geometry.coordinates.slice();

      const name = e.features[0].properties.title;
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      popup
        .setLngLat(coordinates)
        .setHTML(
          `
    <a
    style="color: #F76C35; text-decoration: none; font-size: 1rem; font-weight: 400; line-height: 123.5%;"
    href="https://beegru.com/${props.title}/${
      e.features[0].properties.slug || e.features[0].properties._id
    }"
    >${name}</a>
    `
        )
        .addTo(map.current);
    });
    map.current.on("mouseenter", "properties-point", () => {
      map.current.getCanvas().style.cursor = "pointer";
    });
    map.current.on("mouseleave", "properties-point", () => {
      map.current.getCanvas().style.cursor = "";
    });
    map.current.on("mouseenter", "properties-heat", () => {
      map.current.getCanvas().style.cursor = "pointer";
    });
    map.current.on("mouseleave", "properties-heat", () => {
      map.current.getCanvas().style.cursor = "";
    });
  }, [isMapboxThingyLoaded, props.GeoJSON, props.price, props.title]);

  return (
    <React.Fragment>
      <MainContainer>
        <TitleContainer>
          <Title variant="h6">{props.title} Heat Map</Title>
        </TitleContainer>
        <MapContainer>
          <Map id={"mapbox_id"} ref={map} />
        </MapContainer>
      </MainContainer>
    </React.Fragment>
  );
};

/*

& FInally, let's export the component

*/

export default MapBoxHeatMap;
