/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { NavigateNext } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Breadcrumbs, Chip, Divider, IconButton, Link, Typography, useTheme } from "@mui/material";
import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";
import { useRouter } from "next/router";
import WhatsAppMediaModal from "../../../modal/WhatsAppMediaModal";

// import LanguageModal from "../modal/LanguageModal";
// import LoadingModal from "../modal/LoadingModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "text"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "image"
			? {
					backgroundColor: "rgba(33, 150, 243, 0.5)",
				}
			: id === "document"
				? {
						backgroundColor: "rgba(237, 108, 2, 0.5)",
					}
				: id === "video"
					? {
							backgroundColor: "rgba(76, 175, 80, 0.5)",
						}
					: id === "location"
						? {
								backgroundColor: "rgb(255, 191, 0)",
							}
						: id === "sticker"
							? {
									backgroundColor: "#d5d5d7",
									color: "#000000",
								}
							: id === "audio"
								? {
										backgroundColor: "rgba(66, 46, 85, 0.5)",
									}
								: id === "contacts"
									? {
											backgroundColor: "rgba(180, 199, 113, 0.5)",
										}
									: id === "quick_reply"
										? {
												backgroundColor: "rgba(99, 51, 11, 0.5)",
											}
										: id === "list_picker"
											? {
													backgroundColor: "rgba(78, 115, 194, 0.5)",
												}
											: id === "whatsapp_card"
												? {
														backgroundColor: "rgba(241, 140, 233, 0.5)",
													}
												: id === "catalog"
													? {
															backgroundColor: "rgba(112, 41, 68, 0.5)",
														}
													: id === "call_to_action"
														? {
																backgroundColor: "rgba(26, 150, 144, 0.5)",
															}
														: id === "card"
															? {
																	backgroundColor: "rgba(110, 15, 28, 0.5)",
																}
															: id === "authentication"
																? {
																		backgroundColor: "rgba(30, 212, 236, 0.5)",
																	}
																: id === "unsupported"
																	? {
																			backgroundColor: "#FFC107",
																		}
																	: {
																			backgroundColor: "#FFC107",
																		},
);

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	height: "100%",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2rem",
	width: "2rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const WhatsAppTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	const [languageModal, setLanguageModal] = React.useState(false);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);
	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });
	const [url, setUrl] = React.useState("");
	const [contentType, setContentType] = React.useState("");
	const [message, setMessage] = React.useState("");

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			// FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);
	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
		setDashboardData(DashboardData.DashboardData || []);
	}, [DashboardData]);

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "phone",
			headerName: "Phone",
			width: 150,
			renderCell: (cellValues: any) => {
				if (cellValues.row.user_name === "") {
					return <Typography variant="body1">{cellValues.value}</Typography>;
				}
				return (
					<Link
						href={"https://wa.me/+91" + cellValues.value}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "user_name",
			headerName: "User Name",
			width: 200,
			renderCell: (cellValues: any) => {
				if (!cellValues.value) {
					return;
				}
				if (cellValues.value === "") {
					return <Typography variant="body1">{cellValues.value}</Typography>;
				}
				const name = cellValues.value.first_name + " " + cellValues.value.last_name;

				return (
					<Link
						href={"https://beegru.com/users/" + cellValues.value._id}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{name}
					</Link>
				);
			},
		},
		{
			field: "message_type",
			headerName: "Message Type",
			width: 150,
			renderCell: (cellValues: any) => {
				return cellValues.value?.length > 0 ? (
					<ActionActions>
						<ChipData
							id={cellValues.value}
							label={cellValues.value}
							variant="outlined"
						/>
					</ActionActions>
				) : (
					<ChipData
						id=""
						label=""
						color="primary"
					/>
				);
			},
		},

		{
			field: "message",
			headerName: "Message",
			width: 250,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions
						onClick={() => {
							setUrl(cellValues.row.MediaUrl0);
							setContentType(cellValues.row.MediaContentType0);
							setMessage(cellValues.value);
							setOpenMoadal(true);
						}}
					>
						<Typography
							variant="body1"
							color="primary"
						>
							{cellValues.value?.length > 20 ? cellValues.value.substring(0, 20) + "..." : cellValues.value}
						</Typography>
						<IconButton
							onClick={() => {
								setUrl(cellValues.row.MediaUrl0);
								setContentType(cellValues.row.MediaContentType0);
								setMessage(cellValues.value);
								setOpenMoadal(true);
							}}
						>
							<VisibilityIcon sx={{
								color:"#00AAFF"
							}} />
						</IconButton>
					</ActionActions>
				);
			},
		},

		{
			field: "created_at",
			headerName: "Created At",
			width: 200,
			type: "date" as const,
			valueGetter: (params: any) => {
				return new Date(params); // Ensure this returns a Date object
			},
			renderCell: (params: any) => {
				const date = new Date(params.value);
				return date.toLocaleString(); // Format the date as a string for display
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			WhatsApp
		</Typography>,
	];

	return (
		<MainContainer>
			<WhatsAppMediaModal
				open={openModal}
				onClose={() => setOpenMoadal(false)}
				url={url}
				contentType={contentType}
				message={message}
			/>
			<HeadContainer>
				<HeadText>WhatsApp </HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData.accessLevel &&
			((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("whatsapp")) ||
				DashboardData.accessLevel.includes("super_admin")) ? (
				DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
					<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={dashboardData?.map((item: any, key: number) => {
								return {
									id: item ? item._id : key,
									_id: item ? item._id : "",
									phone: item ? (item.metadata ? item.metadata.From : "") : "",
									user_name: item ? item.user : "",
									message: item ? (item.metadata ? item.metadata.Body : "") : "",
									message_type: item ? (item.metadata ? item.metadata.MessageType : "") : "",
									created_at: item ? item.created_at : "",
									MediaContentType0: item ? (item.metadata ? item.metadata.MediaContentType0 : "") : "",
									MediaUrl0: item ? (item.metadata ? item.metadata.MediaUrl0 : "") : "",
								};
							})}
							getRowId={(row) => row.id}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							filterMode="server"
							onFilterModelChange={(newFilterModel) => {
								setQueryOptions(newFilterModel);
								// FilteringFunction(newFilterModel);
							}}
							sx={{
								backgroundColor: "#FFFFFF",
								"& > .MuiDataGrid-columnSeparator": {
									display: "none",
								},
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
							}}
						/>
					</Box>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default WhatsAppTable;
