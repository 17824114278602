/*

? First, let's import the essentials - React and Styled

*/

import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/

import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import { Box, FormControl, MenuItem, Select, Skeleton, styled, Tab, Tabs, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import AnalyticsGraph from "../analyticsGraph";
import AnalyticsPieChart from "../analyticsPichart";

type CustomAttributesForDivElement = {
	tabColor?: string | number;
};

/*

& Next, let's define the styled components we need on this page

*/

const TabsContainer = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
}));

const CustomTab = styled(Tab, {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	border: tabColor === "primary" ? "2px solid #F76C35" : "none",
	textTransform: "capitalize",
	minWidth: "23rem",
	// width: "100%",
	minHeight: "9rem",
	borderRadius: "8px",
	margin: "0rem 1rem 0.5rem 0rem",
	boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
	backgroundColor: "#ffffff",
}));

const TitleContentDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
	// height: "100%",
	width: "100%",
	gap: "0.5rem",
	color: "#000000",
	textTransform: "lowercase",
	minWidth: "12rem",
	minHeight: "3rem",
}));

const TitleMain = styled("div", {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	border: tabColor === "primary" ? "2px solid #F76C35" : "2px solid rgb(187, 185, 184)",
	borderRadius: "8px",
}));
const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	textTransform: "lowercase",
}));
const TitleAndIcon = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	fontSize: "1rem",
	width: "100%",
}));
const TabContentContainer = styled("div")(({ theme }) => ({
	width: "100%",
	// gap: "1rem",
	[theme.breakpoints.down("sm")]: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const ContainerForSelect = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const ContainerFortotal = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	height: "100%",
	width: "100%",
	gap: "0.5rem",
}));

const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
}));
const FormControlContainer = styled(FormControl)(({ theme }) => ({
	width: "40%",
	height: "2rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
	border: "none",
}));

const SelectContainer = styled(Select)(({ theme }) => ({
	// width: "100%",
	height: "100%",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
	// border: "2px solid #F76C35",
	"& .MuiSelect-select": {
		padding: "0rem 0rem 0rem 0rem",
		margin: "0rem 0rem 0rem 0rem",
		border: "none",
	},
}));
/*

& Next, let's define the tab panel component

*/

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={5}>{children}</Box>}
		</div>
	);
}

const FilesAnalytics = ({
	filesUpload,
	filesSize,
}: {
	filesUpload: any;
	filesSize: any;
}) => {
	/*

  & Next, let's declare the local state variables we need on this page

  */

	const [value, setValue] = React.useState(0);

	/*

  & Next, let's define the event handlers we need on this page

  */

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const [selectedForFileSize, setselectedForFileSize] = React.useState("week");

	const [selectedForFiles, setselectedForFiles] = React.useState("week");

	const [fileSizeData, setFileSizeData] = React.useState<any>();

	const [filesData, setFilesData] = React.useState<any>();

	const handleChangeForFilesSize = async (event: SelectChangeEvent) => {
		setselectedForFileSize(event.target.value as string);
		switch (event.target.value) {
			case "month":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/files-size-monthly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setFileSizeData(data.payload);
					});

				break;
			case "year":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/files-size-yearly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setFileSizeData(data.payload);
					});
				break;
			case "week":
				break;
			case "yesterday":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/files-size-daily", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setFileSizeData(data.payload);
					});
				break;
			default:
				break;
		}
	};
	const handleChangeForFiles = async (event: SelectChangeEvent) => {
		setselectedForFiles(event.target.value as string);
		switch (event.target.value) {
			case "month":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/files-updated-monthly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setFilesData(data.payload);
					});

				break;
			case "year":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/files-updated-yearly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setFilesData(data.payload);
					});
				break;
			case "week":
				break;
			case "yesterday":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/files-updated-daily", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setFilesData(data.payload);
					});
				break;
			default:
				break;
		}
	};

	React.useEffect(() => {
		setFilesData(filesUpload);
		setFileSizeData(filesSize);
	}, [filesUpload, filesSize]);

	if (!filesData || !fileSizeData) {
		return (
			<div style={{ width: "100%", height: "100vh",padding:"2rem" }}>
				<TabsContainer>
					<Skeleton
						sx={{
							border: "none",
							textTransform: "capitalize",
							minWidth: "23rem",
							// width: "100%",
							minHeight: "9rem",
							borderRadius: "8px",
							margin: "0rem 1rem 0.5rem 0rem",
							boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
							backgroundColor: "#ffffff",
						}}
						variant="rectangular"
						animation="wave"
					/>
					<Skeleton
						sx={{
							border: "none",
							textTransform: "capitalize",
							minWidth: "23rem",
							// width: "100%",
							minHeight: "9rem",
							borderRadius: "8px",
							margin: "0rem 1rem 0.5rem 0rem",
							boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
							backgroundColor: "#ffffff",
						}}
						variant="rectangular"
						animation="wave"
					/>
				</TabsContainer>
				<TabContentContainer>
					<Skeleton
						sx={{
							border: "none",

							backgroundColor: "#ffffff",
						}}
						variant="rectangular"
						animation="wave"
						width={"100%"}
						height={"100vh"}
					/>
				</TabContentContainer>
			</div>
		);
	}

	return (
		<React.Fragment>
			<TabsContainer>
				<Tabs
					value={value}
					orientation="horizontal"
					variant="scrollable"
					scrollButtons={true}
					allowScrollButtonsMobile
					onChange={handleChange}
					TabIndicatorProps={{
						style: {
							backgroundColor: "transparent",
						},
					}}
				>
					<CustomTab
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 0 ? "primary" : "default"}>
									<Typography
										sx={{
											textTransform: "uppercase",
											padding: "0.5rem",
										}}
									>
										Files
									</Typography>
								</TitleMain>
								<SubContainer>
									<ContainerFortotal>
										all time
										<Title>{filesData?.total_files}</Title>
									</ContainerFortotal>
									<ContainerFortotal sx={{ color: "#F76C35" }}>
										{selectedForFiles === "week"
											? "this week"
											: selectedForFiles === "month"
												? "this month"
												: selectedForFiles === "year"
													? "this year"
													: selectedForFiles === "yesterday"
														? "today"
														: null}
										<Title>
											{selectedForFiles === "week"
												? filesData?.files_this_week
												: selectedForFiles === "month"
													? filesData?.this_month_files
													: selectedForFiles === "year"
														? filesData?.this_year_files
														: selectedForFiles === "yesterday"
															? filesData?.files_today
															: null}
										</Title>
									</ContainerFortotal>
								</SubContainer>
								<ContainerForSelect>
									<TitleAndIcon>
										{selectedForFiles === "week" ? (
											filesData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForFiles === "month" ? (
											filesData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForFiles === "year" ? (
											filesData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForFiles === "yesterday" ? (
											filesData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : null}
										{selectedForFiles === "week"
											? filesData?.percentage_change_display_string
											: selectedForFiles === "month"
												? filesData?.percentage_change_display_string
												: selectedForFiles === "year"
													? filesData?.percentage_change_display_string
													: selectedForFiles === "yesterday"
														? filesData?.percentage_change_display_string
														: null}
									</TitleAndIcon>
									<FormControlContainer>
										<SelectContainer
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedForFiles}
											onChange={handleChangeForFiles}
										>
											<MenuItem value={"month"}>Month</MenuItem>
											<MenuItem value={"year"}>Year</MenuItem>
											<MenuItem value={"week"}> week</MenuItem>
											<MenuItem value={"yesterday"}>yesterday</MenuItem>
										</SelectContainer>
									</FormControlContainer>
								</ContainerForSelect>
							</TitleContentDiv>
						}
						value={0}
						tabColor={value === 0 ? "primary" : "default"}
					/>
					<CustomTab
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 1 ? "primary" : "default"}>
									<Typography
										sx={{
											textTransform: "uppercase",
											padding: "0.5rem",
										}}
									>
										Files size
									</Typography>
								</TitleMain>
								<SubContainer>
									<ContainerFortotal>
										all time
										<Title sx={{ textTransform: "capitalize" }}>{fileSizeData.total_size} GB</Title>
									</ContainerFortotal>
									<ContainerFortotal sx={{ color: "#F76C35" }}>
										{selectedForFileSize === "week"
											? "this week"
											: selectedForFileSize === "month"
												? "this month"
												: selectedForFileSize === "year"
													? "this year"
													: selectedForFileSize === "yesterday"
														? "today"
														: null}
										<Title sx={{ textTransform: "capitalize" }}>
											{selectedForFileSize === "week"
												? fileSizeData.sizes_this_week + " GB"
												: selectedForFileSize === "month"
													? fileSizeData?.this_month_sizes + " GB"
													: selectedForFileSize === "year"
														? fileSizeData?.sizes_this_year + " GB"
														: selectedForFileSize === "yesterday"
															? fileSizeData?.sizes_today + " GB"
															: null}
										</Title>
									</ContainerFortotal>
								</SubContainer>
								<ContainerForSelect>
									<TitleAndIcon>
										{selectedForFileSize === "week" ? (
											fileSizeData.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForFileSize === "month" ? (
											fileSizeData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForFileSize === "year" ? (
											fileSizeData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForFileSize === "yesterday" ? (
											fileSizeData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : null}
										{selectedForFileSize === "week"
											? fileSizeData.percentage_change_display_string
											: selectedForFileSize === "month"
												? fileSizeData?.percentage_change_display_string
												: selectedForFileSize === "year"
													? fileSizeData?.percentage_change_display_string
													: selectedForFileSize === "yesterday"
														? fileSizeData?.percentage_change_display_string
														: null}
									</TitleAndIcon>
									<FormControlContainer>
										<SelectContainer
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedForFileSize}
											onChange={handleChangeForFilesSize}
										>
											<MenuItem value={"month"}>Month</MenuItem>
											<MenuItem value={"year"}>Year</MenuItem>
											<MenuItem value={"week"}> week</MenuItem>
											<MenuItem value={"yesterday"}>yesterday</MenuItem>
										</SelectContainer>
									</FormControlContainer>
								</ContainerForSelect>
							</TitleContentDiv>
						}
						value={1}
						tabColor={value === 1 ? "primary" : "default"}
					/>
				</Tabs>
			</TabsContainer>
			<TabContentContainer>
				<TabPanel
					value={value}
					index={0}
				>
					{selectedForFiles === "month" ? (
						<>
							<AnalyticsGraph
								title="Files Monthly"
								graphData={filesData?.line_graph}
							/>
							<AnalyticsPieChart
								title="Files"
								graphData={filesData?.pi_chart}
							/>
						</>
					) : selectedForFiles === "year" ? (
						<>
							<AnalyticsGraph
								title="Files Yearly"
								graphData={filesData?.line_graph}
							/>
							<AnalyticsPieChart
								title="Files"
								graphData={filesData?.pi_chart}
							/>
						</>
					) : selectedForFiles === "week" ? (
						<>
							<AnalyticsGraph
								title="Files Weekly"
								graphData={filesData?.line_graph}
							/>
							<AnalyticsPieChart
								title="Files"
								graphData={filesData?.pi_chart}
							/>
						</>
					) : (
						<>
							<AnalyticsGraph
								title="Files Hourly"
								graphData={filesData?.line_graph}
							/>
							<AnalyticsPieChart
								title="Files"
								graphData={filesData?.pi_chart}
							/>
						</>
					)}
				</TabPanel>
				<TabPanel
					value={value}
					index={1}
				>
					{selectedForFileSize === "month" ? (
						<>
							<AnalyticsGraph
								title="Files size Monthly"
								graphData={fileSizeData?.line_graph}
							/>
							<AnalyticsPieChart
								title="Files size"
								graphData={fileSizeData?.pi_chart}
							/>
						</>
					) : selectedForFileSize === "year" ? (
						<>
							<AnalyticsGraph
								title="Files size Yearly"
								graphData={fileSizeData?.line_graph}
							/>
							<AnalyticsPieChart
								title="Files size"
								graphData={fileSizeData?.pi_chart}
							/>
						</>
					) : selectedForFileSize === "week" ? (
						<>
							<AnalyticsGraph
								title="Files size Weekly"
								graphData={fileSizeData?.line_graph}
							/>
							<AnalyticsPieChart
								title="Files size"
								graphData={fileSizeData?.pi_chart}
							/>
						</>
					) : (
						<>
							<AnalyticsGraph
								title="Files size Hourly"
								graphData={fileSizeData?.line_graph}
							/>
							<AnalyticsPieChart
								title="Files size"
								graphData={fileSizeData?.pi_chart}
							/>
						</>
					)}
				</TabPanel>
			</TabContentContainer>
		</React.Fragment>
	);
};

export default FilesAnalytics;
