import { Check, Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	CircularProgress,
	CircularProgressProps,
	DialogActions,
	DialogTitle,
	MenuItem,
	styled,
	TextField,
	Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { ObjectId } from "bson";
import React from "react";
import uploadFiles from "../../lib/uploadFiles";
import validateFiles from "../../lib/validateFiles";

interface FileAndMore {
	file: File;
	mime_type: string;
}

interface UploadProgress {
	fileId: ObjectId | null;
	percentage: number;
	stage: string;
}
const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
}));

const InnerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "1rem",
}));
const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	padding: "1rem 1rem 1rem 1rem",
	margin: "1rem",
	width: "100%",
	border: " 1px solid black",
	borderRadius: "1rem",
}));
const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const ProgressContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
}));

const UploadModal = ({
	open,
	onClose,
	business_id,
	user_id,
}: {
	open: any;
	onClose: any;
	business_id: string | ObjectId;
	user_id: string | ObjectId;
}) => {
	const [docsNumber, setDocsNumber] = React.useState<string>("");

	const [docsName, setDocsName] = React.useState<string>("");

	const [docsType, setDocsType] = React.useState<string>("");

	const [percentage, setPercentage] = React.useState<Array<number>>([0]);

	const [stage, setStage] = React.useState<Array<string>>(["NOT_STARTED"]);

	const [loading, setLoading] = React.useState<boolean>(false);

	const [load, setLoad] = React.useState<boolean>(false);

	const [selectedFiles, setSelectedFiles] = React.useState<Array<FileAndMore>>([]);

	const [selectedFilePreview, setSelectedFilePreview] = React.useState<Array<string>>([]);

	const [fileId, setFileId] = React.useState<string>("");

	const [mime_type, setMime_type] = React.useState<string>("");

	const currencies = [
		{
			value: "USD",
			label: "$",
		},
		{
			value: "EUR",
			label: "€",
		},
		{
			value: "BTC",
			label: "฿",
		},
		{
			value: "JPY",
			label: "¥",
		},
	];

	const handleUpload = async (validFiles: Array<FileAndMore>, filePreview: Array<string>) => {
		setLoad(true);

		const session = await fetch("/api/kyc-table/business/find-logged-user-sessionId", {
			method: "POST",
			body: JSON.stringify({
				userId: user_id,
			}),
		});
		const sessionId = (await session.json()).payload;
		await uploadFiles(
			sessionId,
			validFiles.map((file: FileAndMore) => ({
				file: file.file,
				mime_type: file.mime_type,
				original_file_id: new ObjectId(),
				original_file_server_path: "",
				original: true,
				is_business_profile: false,
				business_profile_id: new ObjectId(),
				entity: "user",
				entity_id: user_id,
				use_case: "Internal_use_Documents",
				uiPath: "/",
				isPublic: false,
				text: "",
			})),
			(uploadProgress: Array<UploadProgress>) => {
				const data = uploadProgress.map((progress) => progress.fileId);
				const stageInfo = uploadProgress.map((progress) => progress.stage);
				setPercentage(uploadProgress.map((progress) => progress.percentage));
				stage[0] != "NOT_STARTED" ? setStage([...stage, ...stageInfo]) : setStage(stageInfo);
				setFileId(
					uploadProgress
						.map((progress) => progress.fileId)
						.filter((fileId) => fileId !== null)[0]
						?.toString(),
				);
				setMime_type(validFiles[0].mime_type);
			},
		);
		setLoad(false);
	};

	function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
		return (
			<Box sx={{ position: "relative", display: "inline-flex" }}>
				<CircularProgress
					variant="determinate"
					{...props}
				/>
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: "absolute",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography
						variant="caption"
						component="div"
						sx={{ color: "text.secondary" }}
					>{`${Math.round(props.value)}%`}</Typography>
				</Box>
			</Box>
		);
	}

	const handleSave = async () => {
		const response = await fetch("/api/kyc-table/user/upload-verified-documents", {
			method: "POST",
			body: JSON.stringify({
				userId: user_id,
				businessId: business_id,
				document_file_id: fileId,
				mime_type: mime_type,
				document_name: docsName,
				document_number: docsNumber,
				document_type: docsType,
			}),
		});
		const data = await response.json();
		if (data.status === "success") {
			onClose();
			setSelectedFiles([]);
			setSelectedFilePreview([]);
			setDocsName("");
			setDocsNumber("");
			setDocsType("");
			setStage(["NOT_STARTED"]);
		}
	};

	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="xs"
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						color: "primary.main",
					}}
					component="div"
				>
					Upload Verified Document
					<Close
						aria-label="close"
						onClick={() => {
							onClose();
							setSelectedFiles([]);
							setSelectedFilePreview([]);
							setDocsName("");
							setDocsNumber("");
							setDocsType("");
							setStage(["NOT_STARTED"]);
						}}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<DialogContent>
					<InnerContainer>
						<Typography component="span">{"Document number :"}</Typography>
						<TextField
							id="outlined-basic1"
							label="Document number"
							variant="outlined"
							size="small"
							value={docsNumber}
							onChange={(e) => setDocsNumber(e.target.value)}
						/>
					</InnerContainer>
					<InnerContainer>
						<Typography component="span">{"Document name :"}</Typography>
						<TextField
							id="outlined-basic2"
							label="Document name"
							variant="outlined"
							size="small"
							value={docsName}
							onChange={(e) => setDocsName(e.target.value)}
						/>
					</InnerContainer>
					<InnerContainer>
						<Typography component="span">{"Document type :"}</Typography>
						<TextField
							id="outlined-basic3"
							label="Document number"
							variant="outlined"
							size="small"
							select
							defaultValue="EUR"
							helperText="Please select your currency"
							value={docsType}
							onChange={(e) => setDocsType(e.target.value)}
						>
							{currencies.map((option) => (
								<MenuItem
									key={option.value}
									value={option.value}
								>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</InnerContainer>
					<MainContainer>
						<SubContainer>
							<input
								type="file"
								id="file"
								name="file"
								aria-label="file"
								accept="application/pdf"
								multiple={false}
								onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
									const files = event.target.files;
									const customMimeTypes = ["application/pdf"];
									const customValidExtensions = ["pdf"];
									const uploadType: string = "post";
									if (files && files.length > 0) {
										const {
											validFiles,
											invalidFiles,
										}: {
											validFiles: Array<FileAndMore>;
											invalidFiles: Array<File>;
										} = await validateFiles(Array.from(files), customMimeTypes, customValidExtensions, uploadType);
										if (validFiles.length > 0) {
											setSelectedFiles([...selectedFiles, ...validFiles]);

											const filePreview = [
												...selectedFilePreview,
												...validFiles.map((file: FileAndMore) => URL.createObjectURL(file.file)),
											];
											setSelectedFilePreview(filePreview);
											handleUpload(validFiles, filePreview);
										}
										if (invalidFiles.length > 0) {
											const fileNames: Array<string> = invalidFiles.map((file: File) => file.name);
										} else {
										}
									}
								}}
							/>
							{selectedFilePreview.map((file: string, index: number) =>
								file !== "" ? (
									<ProgressContainer key={index}>
										{stage[index] && stage[index] !== "FETCHED_FILE_INFORMATION" ? (
											// <CircularProgress
											// 	size="1.5rem"
											// 	value={percentage[index]}
											// 	variant="determinate"
											// />
											<CircularProgressWithLabel value={percentage[index]} />
										) : stage[index] === undefined || stage[index] === "FETCHED_FILE_INFORMATION" ? (
											<Check
												sx={{
													color: "#4CC417",
													fontSize: "2rem",
													backgroundColor: "rgba(0, 0, 0, 0.5)",
													borderRadius: "50%",
												}}
											/>
										) : null}
									</ProgressContainer>
								) : null,
							)}
						</SubContainer>
					</MainContainer>
				</DialogContent>
				<DialogActions>
					<ViewOrUploadButton
						variant="outlined"
						sx={{
							color: "#FC8019",
						}}
						autoFocus
						onClick={() => {
							onClose();
							setSelectedFiles([]);
							setSelectedFilePreview([]);
							setDocsName("");
							setDocsNumber("");
							setDocsType("");
							setStage(["NOT_STARTED"]);
						}}
					>
						Cancel
					</ViewOrUploadButton>
					<ViewOrUploadButton
						variant="contained"
						onClick={() => {
							handleSave();
						}}
						disabled={
							(selectedFiles.length > 0 && stage.every((stage) => stage !== "FETCHED_FILE_INFORMATION")) ||
							docsName === "" ||
							docsNumber === "" ||
							docsType === ""
						}
					>
						Save
					</ViewOrUploadButton>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default UploadModal;
