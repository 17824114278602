/*
? First, let's import the essentials - React and Styled
*/
import { styled } from "@mui/system";
import React from "react";
/*
? Next, let's import all the UI components we need on this page
*/
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Divider, Link, Typography } from "@mui/material";
import MainAnalyticstab from "./MainAnalytics";

/*
& Next, let's define the styled components we need on this page
*/
const MainContainer = styled("div")(({ theme }) => ({
	width: "100%",
	height: "calc(100vh - 4rem)",
	overflowY: "scroll",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));
const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	// margin: "0rem 0rem 0rem 1rem",
	padding: "0.5rem 0.5rem 0.5rem 0.5rem",
}));
const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
}));

/*
& Next, let's describe the component
*/
const AnalyticsMain = ({ DashboardData, session }: { DashboardData: any; session: any }) => {


	/*

    & Let's define the breadcrumbs for this page and the function that handles the click event

  */

	const handleClick = () => {};

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Analytics
		</Typography>,
	];

	return (
		<React.Fragment>
			<MainContainer>
				<HeadContainer>
					<HeadText>Analytics</HeadText>
					<Breadcrumbs
						separator={<NavigateNextIcon fontSize="small" />}
						aria-label="breadcrumb"
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				{DashboardData.accessLevel &&
				((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("analytics")) ||
					DashboardData.accessLevel.includes("super_admin")) ? (
					!DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
						<>
							{/* <Analyticstab DashboardData={DashboardData} /> */}
							<MainAnalyticstab DashboardData={DashboardData} />
						</>
					)
				) : (
					<YouDontHaveAccess>
						<Typography
							variant="h6"
							color="text.secondary"
						>
							You don&apos;t have access to this page
						</Typography>
					</YouDontHaveAccess>
				)}
			</MainContainer>
		</React.Fragment>
	);
};
/*
& FInally, let's export the component
*/
export default AnalyticsMain;
