import CloseIcon from "@mui/icons-material/Close";
import { Button, DialogActions, DialogTitle, IconButton, TextField, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useRouter } from "next/router";

import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const MainContainer = styled(DialogContentText)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
}));
const ColumnContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "1.5rem",
}));

const RemoveContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.5rem",
}));

const EditContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "1rem",
}));

const EditIconContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "0rem",
}));

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
	position: "absolute",
	zIndex: 1,
	top: 0,
	left: 0,
	height: "4rem",
	width: "100%",
}));

const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const CreateNewWhatsAppTemplatesModal = ({ open, onClose }: { open: any; onClose: any }) => {
	const [name, setName] = React.useState("");
	const [content_template_sid, setContentTemplateSid] = React.useState("");
	const [created_at, setCreatedAt] = React.useState(dayjs());

	const [content_template_variables, setContentTemplateVariables] = React.useState([
		{
			key: "",
			value: "",
		},
	]);

	const router = React.useRef(useRouter()).current;

	const handleVariableAdd = () => {
		setContentTemplateVariables([
			...content_template_variables,
			{
				key: "",
				value: "",
			},
		]);
	};

	const handleVariableRemove = (index: any) => {
		const newArray = [...content_template_variables];
		newArray.splice(index, 1);
		setContentTemplateVariables(newArray);
	};

	const submit = async () => {
		await fetch(process.env.PRODUCTION_API_URL + "create-new-twilio-wtsp-templates", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
			},
			body: JSON.stringify({
				name: name,
				content_template_sid: content_template_sid,
				content_template_variables: content_template_variables,
				created_at: created_at,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
				},
			},
			undefined,
			{
				shallow: true,
			},
		);

		setName("");
		setContentTemplateSid("");
		setCreatedAt(dayjs());
		setContentTemplateVariables([
			{
				key: "",
				value: "",
			},
		]);
		onClose();
	};

	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				maxWidth="xs"
				fullWidth={true}
			>
				<DialogTitle>Add New Content Template</DialogTitle>
				<Container>
					<Close
						aria-label="close"
						onClick={() => {
							setName("");
							setContentTemplateSid("");
							setCreatedAt(dayjs());
							setContentTemplateVariables([
								{
									key: "",
									value: "",
								},
							]);
							onClose();
						}}
						sx={{
							position: "absolute",
							right: "0.5rem",
							top: "0.5rem",
							color: "#F76C35",
						}}
					>
						<CloseIcon />
					</Close>
				</Container>
				<DialogContent>
					<MainContainer>
						<TextField
							id="outlined-basic"
							label="Name"
							type="text"
							variant="outlined"
							value={name}
							fullWidth={true}
							onChange={(event) => {
								setName(event.target.value);
							}}
						/>
						<TextField
							id="outlined-basic"
							label="content template sid"
							type="text"
							variant="outlined"
							fullWidth={true}
							value={content_template_sid}
							onChange={(event) => {
								setContentTemplateSid(event.target.value);
							}}
						/>

						{content_template_variables.map((item: any, index: number) => (
							<ColumnContainer key={index}>
								<div
									key={index}
									style={{ width: "100%" }}
								>
									<RemoveContainer>
										<EditContainer>
											<TextField
												variant="outlined"
												size="small"
												fullWidth
												autoComplete="off"
												label="value"
												value={item.value}
												onChange={(e) => {
													const { value } = e.target;
													const newArray = [...content_template_variables];
													let newValue = value;
													const newKey = newArray[index].key;
													newArray[index] = { key: newKey, value: newValue };
													setContentTemplateVariables(newArray);
												}}
											/>

											<TextField
												variant="outlined"
												size="small"
												fullWidth
												autoComplete="off"
												type="number"
												label="key"
												value={item.key}
												onChange={(e) => {
													const { value } = e.target;
													const newArray = [...content_template_variables];
													let newKey = value;
													const newValue = newArray[index].value;
													newArray[index] = { key: newKey, value: newValue };
													setContentTemplateVariables(newArray);
												}}
											></TextField>
										</EditContainer>

										<IconButton
											color="primary"
											onClick={() => {
												handleVariableRemove(index);
											}}
										>
											<DeleteOutlineIcon />
										</IconButton>
									</RemoveContainer>
									{content_template_variables.length - 1 === index && (
										<IconButton
											color="primary"
											onClick={() => handleVariableAdd()}
											sx={{ margin: "1rem 0rem 0rem 0rem", fontSize: "0.875rem" }}
										>
											<AddIcon />
										</IconButton>
									)}
								</div>
							</ColumnContainer>
						))}

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label="created at"
								value={created_at}
								sx={{
									width: "100%",
								}}
								onChange={(newValue) => {
									if (newValue) {
										setCreatedAt(newValue);
									}
								}}
								disableFuture
							/>
						</LocalizationProvider>
					</MainContainer>
				</DialogContent>
				<DialogActions>
					<ViewOrUploadButton
						variant="outlined"
						sx={{
							color: "#FC8019",
						}}
						autoFocus
						onClick={() => {
							setName("");
							setContentTemplateSid("");
							setCreatedAt(dayjs());
							setContentTemplateVariables([
								{
									key: "",
									value: "",
								},
							]);
							onClose();
						}}
					>
						cancel
					</ViewOrUploadButton>
					<ViewOrUploadButton
						variant="contained"
						autoFocus
						disabled={
							name === "" ||
							content_template_sid === "" ||
							content_template_variables.some((item) => item.key === "" || item.value === "")
						}
						onClick={() => {
							submit();
						}}
					>
						Done
					</ViewOrUploadButton>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CreateNewWhatsAppTemplatesModal;
