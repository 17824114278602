import { ApexOptions } from "apexcharts";
// @mui

// ----------------------------------------------------------------------

export default function BaseOptionChart(): ApexOptions {
	// const theme = useTheme();

	return {
		chart: {
			toolbar: { show: false },
			zoom: { enabled: false },
			animations: {
				enabled: true,
				speed: 800,
			},
		},

		// Fill
		fill: {
			opacity: 1,
			gradient: {
				type: "vertical",
				shadeIntensity: 0,
				opacityFrom: 0.4,
				opacityTo: 0,
				stops: [0, 100],
			},
			colors: ["#C08552", "#F76C35", "#9C27B0", "#ff00ff", "#3F51B5", "#808080", "#03A9F4"],
		},
		theme: {
			mode: "light",
			palette: "palette1",
			monochrome: {
				enabled: false,
				color: "#fff",
				shadeTo: "light",
				shadeIntensity: 0.65,
			},
		},

		// Datalabels
		dataLabels: {
			enabled: true,
			style: {
				fontSize: "1rem",
				fontWeight: "400",
				colors: ["#C08552", "#F76C35", "#9C27B0", "#ff00ff", "#3F51B5", "#808080", "#03A9F4"],
			},
			//   formatter: function (val, opt) {
			//     return opt.w.config.series[0].name.includes("Time")
			//       ? generateTimeString(val as number)
			//       : val + "";
			//   },
		},
		colors: ["#C08552", "#F76C35", "#9C27B0", "#ff00ff", "#3F51B5", "#808080", "#03A9F4"],
	};
}
