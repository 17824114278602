/*

? First, let's import the essentials - React and Styled

*/

import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/

import { Box, styled, Tab, Tabs } from "@mui/material";
import BeegruPointsTab from "./sub-component/BeegruPoints";
import BusinessProfilesAnalytics from "./sub-component/BusinessProfilesData";
import FilesData from "./sub-component/FilesData";
import PropertiesAnalytics from "./sub-component/PropertiesData";
import SearchAnalytics from "./sub-component/SearchLogsData";
import ServicesAnalytics from "./sub-component/ServicesData";
import UsersData from "./sub-component/UsersData";

type CustomAttributesForDivElement = {
	tabColor?: string | number;
};

/*

& Next, let's define the styled components we need on this page

*/

const TabsContainer = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
}));

const CustomTab = styled(Tab, {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	border: tabColor === "primary" ? "2px solid #F76C35" : "none",
	textTransform: "capitalize",
	borderRadius: "8px",
	margin: "1rem 1rem 1rem 0rem",
	boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
	backgroundColor: "#ffffff",
	// transition: "all 0.3s ease",
	" &.MuiTab-root": {
		"&.Mui-selected": {
			backgroundColor: "#F76C35",
			color: "#ffffff",
		},
	},
}));

const TabContentContainer = styled("div")(({ theme }) => ({
	width: "100%",
	padding: "0rem 0rem 0rem 0rem",
	// gap: "1rem",
	[theme.breakpoints.down("sm")]: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
}));

/*

& Next, let's define the tab panel component

*/

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box
					sx={{
						padding: "1rem 0rem 0rem 0rem",
					}}
				>
					{children}
				</Box>
			)}
		</div>
	);
}

const MainAnalyticstab = ({ DashboardData }: { DashboardData: any }) => {
	/*

  & Next, let's declare the local state variables we need on this page

  */

	const [value, setValue] = React.useState(0);

	const [filesData, setFilesData] = React.useState();
	const [filesSizeData, setFilesSizeData] = React.useState();
	const [propertiesAreaOfOperations, setPropertiesAreaOfOperations] = React.useState();
	const [servicesAreaOfOperations, setServicesAreaOfOperations] = React.useState();
	const [propertyPDFDownloads, setPropertyPDFDownloads] = React.useState();
	const [servicePDFDownloads, setServicePDFDownloads] = React.useState();
	const [searchPerformedInWeeks, setSearchPerformedInWeeks] = React.useState();
	const [dailyActiveUsersGraphData, setDailyActiveUsersGraphData] = React.useState();
	const [monthlyActiveUsersGraphData, setMonthlyActiveUsersGraphData] = React.useState();
	const [topBps, setTopBps] = React.useState();
	const [topBpsTimeSpent, setTopBpsTimeSpent] = React.useState();
	const [agentsAreaOfOperations, setAgentsAreaOfOperations] = React.useState();
	const [developresAreaOfOperations, setDevelopresAreaOfOperations] = React.useState();
	const [landownerAreaOfOperations, setLandownerAreaOfOperations] = React.useState();
	const [professionalAreaOfOperations, setProfessionalAreaOfOperations] = React.useState();

	/*

  & Next, let's define the event handlers we need on this page

  */

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const fetchOptions = {
		method: "POST",
		cache: "no-store" as RequestCache,
		headers: {
			"Content-Type": "text/plain",
			"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
		},
		body: JSON.stringify({}),
	};

	React.useEffect(() => {
		switch (value) {
			case 0:
				break;
			case 1:
				const fetchData = async () => {
					try {
						const [filesDataResponse, filesSizeDataResponse] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/files-data-dashboard", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/files-size-dashboard", fetchOptions),
						]);

						const filesData = await filesDataResponse.json();
						const filesSizeData = await filesSizeDataResponse.json();

						setFilesData(filesData.payload);
						setFilesSizeData(filesSizeData.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 1 && !filesData) {
					fetchData();
				}
				break;
			case 2:
				const fetchPropertyData = async () => {
					try {
						const [propertiesAreaOfOperationsResponse, propertiesPDFDownloadsForWeeks] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/properties-area-of-operations", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/properties-pdf-weekly", fetchOptions),
						]);

						const propertiesAreaOfOperations = await propertiesAreaOfOperationsResponse.json();
						const propertiesPDFDownloadsForWeeksData = await propertiesPDFDownloadsForWeeks.json();
						setPropertiesAreaOfOperations(propertiesAreaOfOperations.payload);
						setPropertyPDFDownloads(propertiesPDFDownloadsForWeeksData.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 2 && !propertiesAreaOfOperations && !propertyPDFDownloads) {
					fetchPropertyData();
				}
			case 3:
				const fetchServicesData = async () => {
					try {
						const [servciesAreaOfOperationsResponse, servicesPDFDownloadsForWeeks] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/services-area-of-operations", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/service-pdf-weekly", fetchOptions),
						]);

						const servicesAreaOfOperations = await servciesAreaOfOperationsResponse.json();
						const servicesPDFDownloadsForWeeksData = await servicesPDFDownloadsForWeeks.json();
						setServicesAreaOfOperations(servicesAreaOfOperations.payload);
						setServicePDFDownloads(servicesPDFDownloadsForWeeksData.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 3 && !servicesAreaOfOperations && !servicePDFDownloads) {
					fetchServicesData();
				}
				break;

			case 4:
				const fetchBpsData = async () => {
					try {
						const [
							topBps,
							topBpsTimeSpent,
							agentsAreaOfOperations,
							developresAreaOfOperations,
							landownerAreaOfOperations,
							professionalAreaOfOperations,
						] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/top-business-profiles-views", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/top-business-profiles-time-spent", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/agents-area-of-operations", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/developers-area-of-operations", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/landowner-area-of-operations", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/professionals-area-of-operations", fetchOptions),
						]);

						const [
							topBpsRes,
							topBpsTimeSpentRes,
							agentsAreaOfOperationsRes,
							developresAreaOfOperationsRes,
							landownerAreaOfOperationsRes,
							professionalAreaOfOperationsRes,
						] = await Promise.all([
							topBps.json(),
							topBpsTimeSpent.json(),
							agentsAreaOfOperations.json(),
							developresAreaOfOperations.json(),
							landownerAreaOfOperations.json(),
							professionalAreaOfOperations.json(),
						]);
						setTopBps(topBpsRes.payload);
						setTopBpsTimeSpent(topBpsTimeSpentRes.payload);
						setAgentsAreaOfOperations(agentsAreaOfOperationsRes.payload);
						setDevelopresAreaOfOperations(developresAreaOfOperationsRes.payload);
						setLandownerAreaOfOperations(landownerAreaOfOperationsRes.payload);
						setProfessionalAreaOfOperations(professionalAreaOfOperationsRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 4 && !topBps && !topBpsTimeSpent) {
					fetchBpsData();
				}
				break;
			case 5:
				const fetchSearchData = async () => {
					try {
						const [searchPerformedInWeeksResponse] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/search-performed-weekly", fetchOptions),
						]);

						const searchPerformedInWeeks = await searchPerformedInWeeksResponse.json();
						setSearchPerformedInWeeks(searchPerformedInWeeks.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 5 && !searchPerformedInWeeks) {
					fetchSearchData();
				}
				break;
			case 6:
				const fetchUsersData = async () => {
					try {
						const [dailyActiveUsers, monthlyActiveUsers] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/daily-active-users", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/monthly-active-users", fetchOptions),
						]);

						const dailyActiveUsersRes = await dailyActiveUsers.json();
						const monthlyActiveUsersRes = await monthlyActiveUsers.json();
						setDailyActiveUsersGraphData(dailyActiveUsersRes.payload);
						setMonthlyActiveUsersGraphData(monthlyActiveUsersRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 6 && !dailyActiveUsersGraphData && !monthlyActiveUsersGraphData) {
					fetchUsersData();
				}
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<React.Fragment>
			<TabsContainer>
				<Tabs
					value={value}
					orientation="horizontal"
					variant="scrollable"
					scrollButtons={true}
					allowScrollButtonsMobile
					onChange={handleChange}
					TabIndicatorProps={{
						style: {
							backgroundColor: "transparent",
						},
					}}
				>
					<CustomTab
						label="Beegru Points"
						value={0}
						tabColor={value === 0 ? "primary" : "default"}
					/>
					<CustomTab
						label="Files"
						value={1}
						tabColor={value === 1 ? "primary" : "default"}
					/>
					<CustomTab
						label="Properties"
						value={2}
						tabColor={value === 2 ? "primary" : "default"}
					/>
					<CustomTab
						label="Services"
						value={3}
						tabColor={value === 3 ? "primary" : "default"}
					/>
					<CustomTab
						label="Business Profile"
						value={4}
						tabColor={value === 4 ? "primary" : "default"}
					/>
					<CustomTab
						label="Search"
						value={5}
						tabColor={value === 5 ? "primary" : "default"}
					/>
					<CustomTab
						label="Users"
						value={6}
						tabColor={value === 6 ? "primary" : "default"}
					/>
					<CustomTab
						label="Others"
						value={7}
						tabColor={value === 7 ? "primary" : "default"}
					/>
				</Tabs>
			</TabsContainer>
			<TabContentContainer>
				<TabPanel
					value={value}
					index={0}
				>
					<BeegruPointsTab DashboardData={DashboardData} />
				</TabPanel>
				<TabPanel
					value={value}
					index={1}
				>
					<FilesData
						filesUpload={filesData}
						filesSize={filesSizeData}
					/>
				</TabPanel>
				<TabPanel
					value={value}
					index={2}
				>
					<PropertiesAnalytics
						propertiesAreaOfOperations={propertiesAreaOfOperations}
						propertyPDFDownloads={propertyPDFDownloads}
					/>
				</TabPanel>
				<TabPanel
					value={value}
					index={3}
				>
					<ServicesAnalytics
						servicesAreaOfOperations={servicesAreaOfOperations}
						servicePDFDownloads={servicePDFDownloads}
					/>
				</TabPanel>
				<TabPanel
					value={value}
					index={4}
				>
					<BusinessProfilesAnalytics
						topBusinessProfiles={topBps}
						topBusinessProfilesTimeSpent={topBpsTimeSpent}
						agentsAreaOfOperations={agentsAreaOfOperations}
						developresAreaOfOperations={developresAreaOfOperations}
						landownerAreaOfOperations={landownerAreaOfOperations}
						professionalAreaOfOperations={professionalAreaOfOperations}
					/>
				</TabPanel>
				<TabPanel
					value={value}
					index={5}
				>
					<SearchAnalytics
						DashboardData={searchPerformedInWeeks}
						searchPerformedData={searchPerformedInWeeks}
					/>
				</TabPanel>
				<TabPanel
					value={value}
					index={6}
				>
					<UsersData
						DashboardData={dailyActiveUsersGraphData}
						dailyActiveUsersGraphData={dailyActiveUsersGraphData}
						monthlyActiveUsersGraphData={monthlyActiveUsersGraphData}
					/>
				</TabPanel>
				<TabPanel
					value={value}
					index={7}
				></TabPanel>
			</TabContentContainer>
		</React.Fragment>
	);
};

export default MainAnalyticstab;
