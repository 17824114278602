import { Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, DialogActions, DialogTitle, styled, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const MainContainer = styled(DialogContentText)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
}));

const InnerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1.2rem",
}));
const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	padding: "1rem 1rem 1rem 1rem",
	margin: "1rem",
	width: "100%",
	border: " 1px solid black",
	borderRadius: "1rem",
	lineHeight: "2rem",
}));
const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));
const WhatsAppMediaModal = ({
	open,
	onClose,
	url,
	contentType,
	message,
}: {
	open: any;
	onClose: any;
	url: any;
	contentType: any;
	message: any;
}) => {
	console.log("url", url, contentType);
	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						color: "primary.main",
					}}
				>
					Media
					<Close
						aria-label="close"
						onClick={onClose}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<DialogContent>
					<InnerContainer>
						<MainContainer>
							<SubContainer>
								{contentType && contentType.includes("image") ? (
									<>
										<img
											src={process.env.PRODUCTION_API_URL + "twilio/with-auth?resource=" + encodeURIComponent(url)}
											alt="media"
											referrerPolicy="unsafe-url"
											style={{ width: "100%", height: "auto" }}
										/>
										<Typography variant="body1">{message}</Typography>
									</>
								) : contentType && contentType.includes("video") ? (
									<>
										<video
											src={process.env.PRODUCTION_API_URL + "twilio/with-auth?resource=" + encodeURIComponent(url)}
											controls
											style={{ width: "100%", height: "auto" }}
										/>
										<Typography variant="body1">{message}</Typography>
									</>
								) : contentType && contentType.includes("audio") ? (
									<>
										<audio
											src={process.env.PRODUCTION_API_URL + "twilio/with-auth?resource=" + encodeURIComponent(url)}
											controls
											style={{ width: "100%", height: "50px" }}
										/>
										<Typography variant="body1">{message}</Typography>
									</>
								) : contentType && contentType.includes("application") ? (
									<>
										<a
											href={url}
											target="_blank"
											rel="noreferrer"
										>
											Download
										</a>
										<Typography variant="body1">{message}</Typography>
									</>
								) : (
									<Typography variant="body1">{message}</Typography>
								)}
							</SubContainer>
						</MainContainer>
					</InnerContainer>
				</DialogContent>
				<DialogActions>
					<ViewOrUploadButton
						onClick={onClose}
						variant="contained"
						color="primary"
					>
						Close
					</ViewOrUploadButton>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default WhatsAppMediaModal;
