/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	DataGridPremium,
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license";

import { Add, ArrowDropDown, Inventory2Outlined, NavigateNext } from "@mui/icons-material";
import {
	Box,
	Breadcrumbs,
	Button,
	Chip,
	debounce,
	Divider,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	Typography,
	useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import ReactTimeAgo from "react-time-ago";
import LoadingModal from "../../../modal/LoadingModal";
import InventoryModal from "../../../modal/PropertyInventoryModal";
import UpdatePropertyReminderModal from "../../../modal/UpdatePropertyReminderModal";
// import LoadingModal from "../../../modal/LoadingModal";
// import InventoryModal from "../../../modal/PropertyInventoryModal";
// import UpdatePropertyReminderModal from "../../../modal/UpdatePropertyReminderModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "untouched"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "work_in_progress"
			? {
					backgroundColor: "rgba(33, 150, 243, 0.5)",
				}
			: id === "pending_qc"
				? {
						backgroundColor: "rgba(237, 108, 2, 0.5)",
					}
				: id === "published"
					? {
							backgroundColor: "rgba(76, 175, 80, 0.5)",
						}
					: id === "publish_requested"
						? {
								backgroundColor: "rgb(255, 191, 0)",
							}
						: id === "edited"
							? {
									backgroundColor: "#d5d5d7",
									color: "#000000",
								}
							: {
									backgroundColor: "rgba(255, 255, 255, 0.002)",
								},
);

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2rem",
	width: "2rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const PropertyDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	const [reminderModal, setReminderModal] = React.useState(false);

	const [propertyId, setPropertyId] = React.useState("");

	const [transactiontype, settransactiontype] = React.useState("");

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [inventoryModal, setInventoryModal] = React.useState(false);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
		setDashboardData(DashboardData.DashboardData || []);
	}, [DashboardData]);

	/*

  & Next, let's define a function to handle the change in the select and update the status

  */

	const handleChange = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "property-table/property-status", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				status: event.target.value,
				propertyId: cellValues.id,
				transactiontype: cellValues.row.transactiontype,
				pre_status: cellValues.row.status,
				email: session ? (session.user ? session.user.email : "") : "",
				github_username: session ? (session.user ? session.username : "") : "",
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const handleChangeAssigned = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "property-table/property-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				assigned_to: event.target.value,
				propertyId: cellValues.id,
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to delete the assigned to.

  */

	const deleteAssigned = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "property-table/property-delete-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				deletedAssignedTo: item,
				propertyId: cellValues.id,
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	const updateFineTuning = async (e: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "property-table/property-use-data-for-fine-tuning", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				use_data_for_fine_tuning: e.target.checked,
				propertyId: cellValues.id,
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	const showInFeatured = async (e: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "property-table/property-show-in-featured", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				show_in_featured: e.target.checked,
				propertyId: cellValues.id,
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "property-table/property-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the data for the menu items

  */

	const menuItems = [
		{
			id: 1,
			label: "Untouched",
			value: "untouched",
		},
		{
			id: 2,
			label: "Work in progress",
			value: "work_in_progress",
		},
		{
			id: 3,
			label: "Pending QC",
			value: "pending_qc",
		},
		{
			id: 4,
			label: "Published",
			value: "published",
		},
		{
			id: 5,
			label: "Publish Requested",
			value: "publish_requested",
		},
		{
			id: 6,
			label: "Edited",
			value: "edited",
		},
	];

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "propertyId",
			headerName: "Property ID",
			width: 150,
		},
		{
			field: "propertyName",
			headerName: "Property Name",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/properties/" + cellValues.row.action}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "slug",
			headerName: "Slug",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/properties/" + cellValues.row.action}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "transactiontype",
			headerName: "Transaction Type",
			width: 150,
		},
		{
			field: "propertytype",
			headerName: "Property Type",
			width: 150,
		},
		{
			field: "propertycategory",
			headerName: "Property Category",
			width: 150,
		},
		{
			field: "location",
			headerName: "Location",
			width: 150,
		},

		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 150,
		},
		{
			field: "is_employee_created",
			headerName: "Created By Employee",
			width: 150,
		},
		{
			field: "deleted",
			headerName: "Deleted",
			width: 150,
		},
		{
			field: "username",
			headerName: "Username",
			width: 160,
			// renderCell: (cellValues: any) => {
			//   return (
			//     <Link
			//       href="/"
			//       sx={{
			//         textDecoration: "none",
			//       }}
			//     >
			//       {cellValues.value}
			//     </Link>
			//   );
			// },
		},
		{
			field: "availability",
			headerName: "Availability",
			width: 160,
			// renderCell: (cellValues: any) => {
			//   return (
			//     <Link
			//       href="/"
			//       sx={{
			//         textDecoration: "none",
			//       }}
			//     >
			//       {cellValues.value}
			//     </Link>
			//   );
			// },
		},
		{
			field: "possession_date",
			headerName: "Possession Date",
			width: 150,
			filterable: false,
			// fiterOperators: ["contains"],
			renderCell: (cellValues: any) => {
			  const date = new Date(cellValues.value);
				const isFutureDate = date > new Date();

				return (
					<ActionActions>
						{!isFutureDate ? (
							<Typography
								variant="body2"
								color="error"
							>
								{date.toDateString()}
							</Typography>
						) : (
							<Typography
								variant="body2"
								color="success"
							>
								{date.toDateString()}
							</Typography>
						)}
					</ActionActions>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			width: 150,
			editable: true,
			// type: "singleSelect",
			valueOptions: menuItems,
			valueFormatter: (params: any) => {
				if (!params) {
					return "untouched";
				}
				return params.value === "untouched"
					? "Untouched"
					: params.value === "work_in_progress"
						? "Work in progress"
						: params.value === "pending_qc"
							? "Pending QC"
							: params.value === "published"
								? "Published"
								: params.value === "publish_requested"
									? "Publish Requested"
									: params.value === "edited"
										? "Edited"
										: "";
			},
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((property: any) => property._id === cellValues.row.id);
				return (
					<ChipData
						id={data ? data.status : cellValues.value}
						label={
							(data ? data.status : cellValues.value) === "untouched"
								? "Untouched"
								: (data ? data.status : cellValues.value) === "work_in_progress"
									? "Work in progress"
									: (data ? data.status : cellValues.value) === "pending_qc"
										? "Pending QC"
										: (data ? data.status : cellValues.value) === "published"
											? "Published"
											: (data ? data.status : cellValues.value) === "publish_requested"
												? "Publish Requested"
												: (data ? data.status : cellValues.value) === "edited"
													? "Edited"
													: ""
						}
						variant="filled"
						onDelete={() => {}}
						deleteIcon={<ArrowDropDown />}
					/>
				);
			},
			renderEditCell: (cellValues: any) => {
				const data = dashboardData.find((property: any) => property._id === cellValues.row.id);
				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						value={data ? data.status : cellValues.value}
						onChange={(event: any) => handleChange(event, cellValues)}
					>
						{menuItems.map((item: any, index: number) => {
							return (
								<MenuItem
									value={item.value}
									key={index}
								>
									{item.label}
								</MenuItem>
							);
						})}
					</Select>
				);
			},
		},
		{
			field: "createdOn",
			headerName: "Property created on",
			width: 150,
			type: "date" as const,
			valueGetter: (params: any) => {
				return new Date(params); // Ensure this returns a Date object
			},
			renderCell: (params: any) => {
				const date = new Date(params.value);
				return date.toLocaleDateString(); // Format the date as a string for display
			},
		},
		{
			field: "last_activity_date",
			headerName: "Last Activity Date",
			width: 180,
			filterable: false,
			renderCell: (cellValues: any) => {
				const is_date_14_days_old = new Date(Date.now() - 12096e5) > new Date(cellValues.value);

				const date = new Date(cellValues.value);

				return (
					<ActionActions>
						{is_date_14_days_old ? (
							<Typography
								variant="body2"
								color="error"
							>
								{date.toLocaleString()}
							</Typography>
						) : (
							<Typography
								variant="body2"
								color="success"
							>
								{date.toLocaleString()}
							</Typography>
						)}
					</ActionActions>
				);
			},
		},
		{
			field: "last_activity",
			headerName: "Last Activity",
			width: 150,
			filterable: false,
			// fiterOperators: ["contains"],
			renderCell: (cellValues: any) => {
				const is_date_14_days_old = new Date(Date.now() - 12096e5) > new Date(cellValues.value);
				return (
					<ActionActions>
						{cellValues.value ? (
							<ReactTimeAgo
								date={new Date(cellValues.value)}
								locale="en-US"
								style={{
									color: is_date_14_days_old ? "#F76C35" : "#000000",
									fontWeight: 400,
								}}
							/>
						) : (
							<Typography
								variant="body2"
								color="error"
							>
								no activity
							</Typography>
						)}
					</ActionActions>
				);
			},
		},
		{
			field: "use_data_for_fine_tuning",
			headerName: "Use Data For Fine Tuning",
			width: 150,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							updateFineTuning(e, cellValues);
						}}
					/>
				);
			},
		},
		{
			field: "show_in_featured",
			headerName: "Show In Featured",
			width: 150,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							showInFeatured(e, cellValues);
						}}
					/>
				);
			},
		},

		{
			field: "assigned_to",
			headerName: "Assigned",
			width: 300,
			editable: true,
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				return data && data.assigned_to?.length > 0 ? (
					<ActionActions>
						{data &&
							data.assigned_to?.map((item: any, index: number) => {
								return (
									<Chip
										id={item._id}
										key={index}
										label={
											item.first_name + " " + item.last_name ? item.first_name + " " + item.last_name : "Assign to"
										}
										variant="outlined"
										onDelete={() => {
											deleteAssigned(item._id, cellValues);
										}}
										color="primary"
									/>
								);
							})}
					</ActionActions>
				) : (
					<Chip
						id="assign"
						label="Assign to"
						variant="outlined"
						onDelete={() => {}}
						deleteIcon={<Add />}
						color="primary"
					/>
				);
			},
			renderEditCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);

				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						onChange={(event: any) => handleChangeAssigned(event, cellValues)}
						value={data ? data.assigned_to : cellValues.value}
					>
						{DashboardData.list_of_moderators.map((item: any, index: number) => {
							return (
								<MenuItem
									value={item._id}
									key={index}
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
									}}
								>
									{item.first_name + " " + item.last_name}
								</MenuItem>
							);
						})}
					</Select>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			width: 250,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Link
							href={"tel:91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/call_blue.svg"
										: "/images/icons/social/call_blue.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://wa.me/+91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/whatsapp_green.svg"
										: "/images/icons/social/whatsapp_green.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://beegru.com/properties/" + cellValues.row.action}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={theme.palette.mode === "dark" ? "/images/icons/social/edit.svg" : "/images/icons/social/edit.svg"}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						{cellValues?.row?.status === "publish_requested" ? (
							<Button
								variant="contained"
								sx={{
									padding: "0.5rem 1rem",
									textTransform: "none",
									borderRadius: "1rem",
									fontSize: "0.75rem",
									background: "primary",
									color: "#fff",
								}}
								onClick={() => {
									setReminderModal(true);
									setPropertyId(cellValues.row.id);
									settransactiontype(cellValues.row.transactiontype);
								}}
							>
								remind
							</Button>
						) : null}
					</ActionActions>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<Button
					sx={{
						color: "#F76C35",
						fontSize: "0.75rem",
					}}
					onClick={() => {
						setInventoryModal(true);
					}}
					startIcon={<Inventory2Outlined fontSize="small" />}
				>
					Inventory
				</Button>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Link
			underline="hover"
			key="2"
			color="inherit"
			href="#"
			onClick={handleClick}
		>
			Moderation
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Properties
		</Typography>,
	];

	return (
		<MainContainer>
			<UpdatePropertyReminderModal
				open={reminderModal}
				onClose={() => {
					setReminderModal(false);
				}}
				propertyId={propertyId}
				transactiontype={transactiontype}
			/>
			<LoadingModal open={openModal} />
			<InventoryModal
				open={inventoryModal}
				onClose={() => {
					setInventoryModal(false);
				}}
				bugdata={""}
			/>
			<HeadContainer>
				<HeadText>Moderation</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData.accessLevel &&
			((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("property")) ||
				DashboardData.accessLevel.includes("super_admin")) ? (
				DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
					<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={dashboardData?.map((item: any, key: number) => {
								const date = new Date(item.created_at);
								return {
									id: item ? item._id : key,
									propertyId: item._id,
									propertyName: item.title,
									slug: item.slug,
									propertytype: item.property_type ? (item.property_type.length > 0 ? item.property_type : "--") : "--",
									propertycategory: item.property_category
										? item.property_category.length > 0
											? item.property_category
											: "--"
										: "--",
									location: item.location ? (item.location.title.length > 0 ? item.location.title : "--") : "--",
									phonenumber: item.created_by
										? item.created_by.user_id
											? item.created_by.user_id.country_code
												? item.created_by.user_id.country_code + " " + item.created_by.user_id.phone
												: "no phone"
											: "no phone"
										: "no phone",
									username: item.created_by
										? item.created_by.business_profile_id
											? item.created_by.business_profile_id.title
											: item.created_by.user_id
												? item.created_by.user_id.first_name + " " + item.created_by.user_id.last_name
												: "no name"
										: "no name",
									status: item.status === "active" ? "untouched" : item.status,
									use_data_for_fine_tuning: item ? item.use_data_for_fine_tuning : false,
									show_in_featured: item ? item.show_in_featured : false,
									assigned_to: item.assigned_to ? item.assigned_to : [],
									action: item.slug,
									transactiontype: item.transaction_type,
									autoHeight: true,
									is_employee_created: item.employee_id ? item.employee_id : "--",
									createdOn: item.created_at ? new Date(item.created_at) : new Date(),
									last_activity_date: item.updated_at ? item.updated_at : item.updated_at,
									last_activity: item.updated_at ? item.updated_at : item.updated_at,
									possession_date: item ? item.possession_date : item.possession_date,
									deleted: item.deleted,
									availability: item ? (item.availability ? item.availability[0] : "--") : "--",
								};
							})}
							getRowId={(row) => row.id}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							filterMode="server"
							onFilterModelChange={(newFilterModel) => {
								setQueryOptions(newFilterModel);
								FilteringFunction(newFilterModel);
							}}
						/>
					</Box>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default PropertyDataTable;
