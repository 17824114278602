import { ApexOptions } from "apexcharts";
import generateTimeString from "../../../lib/generateTimeString";
// @mui

// ----------------------------------------------------------------------

export default function BaseOptionChart(): ApexOptions {
  // const theme = useTheme();

  return {
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      animations: {
        enabled: true,
        speed: 800,
      },
    },

    // Fill
    fill: {
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
      colors: ["#f76c35"],
    },
    theme: {
      mode: "light",
      palette: "palette1",
      monochrome: {
        enabled: false,
        color: "#fff",
        shadeTo: "light",
        shadeIntensity: 0.65,
      },
    },

    // Datalabels
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "1rem",
        colors: ["#000000"],
      },
      formatter: function (val, opt) {
        return opt.w.config.series[0].name.includes("Time")
          ? generateTimeString(val as number)
          : val + "";
      },
    },
    colors: ["#f76c35"],
  };
}
