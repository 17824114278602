/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	DataGridPremium,
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license";

import { Box, debounce, LinearProgress, Link, Skeleton, Typography, useTheme } from "@mui/material";
/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const AllServicesViewsTable = ({
	servicesViews,
	page,
	setPage,
}: {
	servicesViews: any;
	page: number;
	setPage: any;
}) => {
	const theme = useTheme();

	const [servicesViewsData, setservicesViewsData] = React.useState(servicesViews || []);

	const [maxCount, setMaxCount] = React.useState(0);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });
	const [filterPage, setFilterPage] = React.useState(1);

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(servicesViews?.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the row count when the servicesViews changes

  */

	React.useEffect(() => {
		if (!servicesViews) return;
		setservicesViewsData(servicesViews.servicesViews || []);
		setRowCountState(servicesViews.rowCount || 0);
		setMaxCount(servicesViews.maxViewCount || 0);
	}, [servicesViews]);

	React.useEffect(() => {
		if (queryOptions.items.length > 0) {
			setFilterPage(filterPage);
			FilteringFunction(queryOptions);
		} else {
			setPage(page);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel, queryOptions]);

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "all-services-views-table/services-view-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					filter: newFilterModel,
					page: paginationModel.page,
				}),
			});

			const res = await data.json();
			setservicesViewsData(res.payload.servicesViews);
			setRowCountState(res.payload.rowCount);
		},

		[paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
    };


	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "service_id",
			headerName: "Service ID",
			width: 100,
		},
		{
			field: "service_name",
			headerName: "Service Name",
			width: 250,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/services/" + cellValues.row.slug}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "slug",
			headerName: "Slug",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/services/" + cellValues.value}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "service_type",
			headerName: "Service Type",
			width: 150,
		},

		{
			field: "service_location",
			headerName: "Service Location",
			width: 150,
		},
		{
			field: "service_status",
			headerName: "Service Status",
			width: 150,
		},
		{
			field: "views",
			headerName: "Views",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<Box
						sx={{
							display: "flex",
							alignSelf: "center",
							justifyContent: "center",
							alignItems: "center",
							padding: "0.8rem",
							gap: "0.5rem",
						}}
					>
						<Box sx={{ width: "100%" }}>
							<LinearProgress
								variant="determinate"
								value={(cellValues.value / maxCount) * 100}
								sx={{
									height: 10,
									borderRadius: 10,
									backgroundColor: "rgba(0,0,0,0.1)",
								}}
							/>
						</Box>
						<Box sx={{ minWidth: 35 }}>
							<Typography
								variant="body2"
								sx={{ color: "text.secondary" }}
							>
								{cellValues.value}
							</Typography>
						</Box>
					</Box>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	if (
		(!servicesViewsData || servicesViewsData.length === 0 || !servicesViewsData[0]) &&
		queryOptions.items.length === 0
	) {
		return (
			<MainContainer>
				<Box sx={{ height: "50vh", padding: " 1rem", width: "100%" }}>
					<Skeleton
						variant="rectangular"
						width="100%"
						height="100%"
						animation="wave"
						sx={{
							border: "none",
							boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
							backgroundColor: "#ffffff",
						}}
					/>
				</Box>
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<Box sx={{ height: "50vh", padding: " 1rem", width: "100%" }}>
				<DataGridPremium
					disableRowSelectionOnClick={false}
					slots={{ toolbar: GridToolbar }}
					sx={{
						backgroundColor: "#fff",
						borderRadius: "10px",
					}}
					rows={servicesViewsData?.map((item: any, key: number) => {
						return {
							id: item ? item.service_id : key,
							service_id: item.service_id,
							service_name: item.service_name,
							service_type: item.service_type,
							service_location: item.service_location,
							service_status: item.service_status,
							slug: item.service_slug,
							views: item.count,
						};
					})}
					getRowId={(row) => row.id}
					columns={columns}
					pagination={true}
					paginationMode="server"
					rowCount={rowCountState}
					onPaginationModelChange={(paginationModel) => {
                        setPaginationModel(paginationModel);
						if (queryOptions.items.length > 0) {
							setFilterPage(paginationModel.page);
						} else {
							setPage(paginationModel.page);
						}
					}}
					pageSizeOptions={[100]}
					filterMode="server"
					onFilterModelChange={(newFilterModel) => {
						setQueryOptions(newFilterModel);
						FilteringFunction(newFilterModel);
					}}
				/>
			</Box>
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default AllServicesViewsTable;
